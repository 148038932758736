/* ::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: rgb(50, 50, 50);
} */

/* body.dark-scroll::-webkit-scrollbar {
  width: 10px;
}
body.dark-scroll::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: rgb(50, 50, 50);
}
body.light-scroll::-webkit-scrollbar {
  width: 10px;
}
body.light-scroll::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: rgb(220, 220, 220);
} */
#app {
  height: 100dvh;
}
#virtual-table > div> table{
  width: 100%;
}

.table-bg{
  background-color: rgba(255, 255, 255, 0.21);
}

.disable-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge, and Firefox */
.disable-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html {
  overscroll-behavior-y: contain;
}

body {
  position: relative;
  overflow: hidden;
  height: 100%;
  overscroll-behavior-y: contain;
}

.full {
  /* min-height: calc(100dvh - 95px) */
  min-height: calc(100dvh - 178px)
}

.full-admin{
  width: 100%;
  min-height: calc(100dvh - 94px)
}

.remote-modal {
  top: 50%;
  left: 50%;
}

.remote-sub-modal {
  top: 50%;
  left: 50%;
}

.admin-user-modal {
  top: 50%;
  left: 50%;
}

/* bottom of g char in label is cut off without this */
/* this does work but it makes the label a little to tall while in focus and blocks the text in the input */
/* .account-input > label{
  height: -moz-available;          
  height: -webkit-fill-available;  
  height: fill-available;
} */
.hover-back:hover > span {
  color: gray !important;
}
.hover-back:hover > p {
  color: gray !important;
}
/* non MD inputs are too tall */
#country-input>div>div {
  height: 44px !important;
}
#country-input>div>div {
  height: 44px !important;
}

#country-input>div>div>div {
  height: 44px !important;
}

#country-input>div>div>svg {
  top: auto !important;
}
#country-input>div>div>div>input {
  padding-top: 5px;
}
#type-input>div>div {
  height: 44px !important;
}
#type-input>div>div {
  height: 44px !important;
}

#type-input>div>div>div {
  height: 44px !important;
}

#type-input>div>div>svg {
  top: auto !important;
}
#type-input>div>div>div>input {
  padding-top: 5px;
}

.wrapper {
  overflow-y: scroll;
}

/* .MuiGrid-root > .MuiGrid-item {
  padding: 12px !important;
} */

.wrapper2 {
  overflow-x: scroll;
}

#mui_measurement_span {
  overscroll-behavior-y: contain;
  overflow: hidden;
}

#dash-nav.dark-nav {
  background-color: #323232;
}

#dash-nav.light-nav {
  background-color: #DCDCDC;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 2.5px;
  padding-left: 2.5px;
}

.sub_icon>span {
  overflow: unset !important;
}

.clear-btn {
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2),
    0rem 0.0625rem 0.3125rem 0rem rgba(0, 0, 0, 0.12) !important;
  border-radius: 6px !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 8px;
  position: absolute;
  z-index: 10;

}

.tooltip .tooltiptext {
  width: 140px;
  bottom: 100%;
  left: 50%;
  font-size: 12px;
  margin-left: -60px;
}

.MuiSelect-icon {
  display: block !important;
  color: white !important;
  fill: white !important;
}

#dryer-filter>p {
  color: white;
}

#dryer-filter {

  display: flex;
}

.plenum-input {
  width: 100%;
}

.custom-input:focus {
  outline-style: solid;
}

.dryer-dropdown>div>svg {
  top: auto;
}

#dryer-filter>p {

  display: flex;
}

#dryer-filter>span {
  margin-right: 15px;
}

.css-8je8zh-MuiTouchRipple-root {
  border-color: transparent !important;
}

/* Checked effect */
.main input:checked~.checkbox-container {
  background-color: green;
}

.MuiSvgIcon-root {
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
}

.MuiSvgIcon-root>path {
  width: 100% !important;
  height: 100% !important;
}

.MuiTabs-indicator {
  transition-duration: 0.25s !important;
}

div.sideTabNav {
  border: 1px solid rgba(210, 214, 218, 0.6);
  border-radius: 8px;
}

div.sideTabNav>div>span.MuiTabs-indicator {
  box-shadow: none;
}

div.sideTabNav>div>div>a {
  color: white !important;
  font-size: 10px;
}

.feedback-light>div>div>fieldset {
  border-color: white;

}

.feedback-dark>div>div>fieldset {
  border-color: black;

}

#search-box>div>div>fieldset {
  border-radius: 8px;
}

.radius>div>div>fieldset {
  border-radius: 8px;
  background-color: white;
  color: black !important;
}

.radius>div>div {
  height: 40px;
}

.radius>div>div>input {
  z-index: 1;
  color: black;
}

.radius>svg {
  fill: black !important;
  top: auto;
}

#pin-container>div>div>fieldset {
  border-radius: 8px;
}

#search-box>div>div {
  height: 50px;
}

#search-box>div>div>input {
  color: white;
}

div.sideTabNav>div>div>a.tab-active {
  color: white !important;
  background: #862633;
}

div.sideTabNav>div>div>a.tab-inactive {
  color: white !important;
}

.checkbox-style {
  background-color: white !important;
  padding: 0 !important;
  border-radius: 6px !important;
  width: 18px !important;
  height: 18px !important;
}

div.singleViewTabs>div>div>a.tab-active {
  padding: 8px;
  color: white !important;
  background: #862633;
}

.MuiTab-textColorPrimary {
  color: white !important;
}

.Mui-disabled {
  -webkit-text-fill-color: whitesmoke !important;
}

.listItemText {
  margin-left: 0 !important;
}

div.listItemText>p {
  color: white;
  font-size: 11px;
  align-self: center;
}

div.listItemText>span {
  display: inline;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

#multi-select-active {
  font-size: 10px;
  color: white;
  padding: 8px;
  background: #862633;
  border-radius: 6px;
  cursor: pointer;
}

#multi-select-inactive {
  font-size: 10px;
  color: white;
  padding: 8px;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
}

.row-hover {
  display: flex;
  width: 100%;
}

.row-hover:active {
  background-color: rgba(75, 69, 69, 0.2);
  border-radius: 8px;
}

.button-click {
  display: flex;
  width: 100%;
}

.button-click:active {
  background-color: rgba(75, 69, 69, 0.2);
  border-radius: 8px;
}

.bottom-nav-active {
  background: #862633 !important;
  min-width: unset !important;
  /* border-radius: 12px !important; */
}

.bottom-nav-active-left {
  background: #862633 !important;
  border-bottom-right-radius: 8px !important;
  min-width: unset !important;
}

.bottom-nav-active-right {
  background: #862633 !important;
  border-bottom-left-radius: 8px !important;
  min-width: unset !important;
}

.bottom-nav-inactive {
  min-width: unset !important;
}

.feedback-form {
  width: 50%;
}

.remote-modal {
  max-width: 500px;
  width: 50%;
}

.remote-sub-modal {
  max-width: 500px;
  width: 45%;
}

.admin-user-modal {
  max-width: 500px;
  width: 50%;
}

.dryer-card-divider {
  border: 1px solid black;
  margin: 15px;
}

.dryer-card-divider-multi {
  border: 1px solid black;
  margin: 10px;
}

.MuiTypography-root>span {
  display: flex;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100dvh;
}

/* is this doing anything? */
.shadow {
  box-shadow:
    rgba(0, 0, 0, 0.14) 0rem 0.125rem 0.125rem 0rem,
    rgba(0, 0, 0, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgba(0, 0, 0, 0.12) 0rem 0.0625rem 0.3125rem 0rem !important;
}

.error-style>div>fieldset {
  border: 2px solid #F44335;
}

.error-style:hover>div>fieldset {
  border: 2px solid #F44335;
}

.error-style>label {
  color: #F44335;
}

/* #alerts-box{
  background-image: linear-gradient(195deg, #EF5350, #E53935);
} */
#alerts-box-color-red {
  background-image: linear-gradient(195deg, #ff0000, #ff0000);
}

#alerts-box-color-yellow {
  background-image: linear-gradient(195deg, #ffff00, #ffff00);
}

#alerts-box-color-orange {
  background-image: linear-gradient(195deg, #ffaa00, #ffaa00);
}

#alert-menu div:nth-last-of-type(3) {
  background-color: linear-gradient(195deg, #ff0000, #ff0000);
}

#alert-menu-light>div.MuiPaper-root {
  background-color: #E6E6E6 !important;
}

#alert-menu>div.MuiPaper-root {
  background-color: #141414 !important;
}

/* .multi-chip > svg{
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
} */

#chip-container-small>div>span {
  align-self: end;
}

.multi-chip-dark>svg {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  color: rgba(255, 255, 255, 0.26) !important;
  border-color: white !important;
}

.multi-chip-light>svg {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border-color: white !important;
}

.multi-chip-light {

  border-color: black !important;
}

.multi-chip-dark {

  border-color: white !important;
}

.multi-chip-dark-small>svg {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  color: rgba(255, 255, 255, 0.26) !important;
  border-color: white !important;
}

.multi-chip-light-small>svg {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border-color: white !important;
}

.multi-chip-light-small {

  border-color: black !important;
}

.multi-chip-dark-small {

  border-color: white !important;
}

.alert-icon-color-red {
  color: #ff0000 !important;
}

.alert-icon-color-yellow {
  color: #ffff00 !important;
}

.alert-icon-color-orange {
  color: #ffaa00 !important;
}

.alert-icon-color-red-badge {
  background-color: #ff0000 !important;
}

.alert-icon-color-yellow-badge {
  background-color: #ffff00 !important;
}

.alert-icon-color-orange-badge {
  background-color: #ffaa00 !important;
}

#alert-menu div:nth-last-of-type(3) {
  background-color: linear-gradient(195deg, #ff0000, #ff0000);
}

/* #alert-menu div:nth-last-of-type(3) > ul > li > a > span.MuiTypography-root{
  color: yellow !important
} */

.no-hover:hover {
  background-color: transparent !important;
  color: inherit !important;
}

.no-hover-dark>a>span {
  color: white !important;
}

.no-hover-light>a>span {
  color: black !important;
}

#remote-select>p {
  color: black;
}

#underline {
  text-decoration: underline !important;
}

#panel2-header>div>svg {
  margin-left: 16px;
}

#underline:hover {
  color: gray !important;
}

.visible-xs {
  display: none;
}

.dropdown-light>p {
  color: white;
}

.dropdown-dark>p {
  color: black;
}

text g {
  fill: white !important;
}

.box .row.content {
  flex: 1 1 auto;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.visible-xs {
  display: none;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 575px) {

  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }

  @media (pointer: fine) {
    .row-hover:hover {
      background-color: transparent;
      border-radius: 8px;
    }
  }

  .visible-xs {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .feedback-form {
    width: 90%;
  }

  .remote-modal {
    width: 95%;
  }

  .remote-sub-modal {
    width: 95%;
  }

  .admin-user-modal {
    width: 95%;
  }

  .negative-margin {
    margin-top: -4px;
  }
}

@media only screen and (min-width: 768px) {
  .negative-margin {
    margin-left: -4px;
  }
}

@supports (-webkit-touch-callout: none) {

  html,
  body {
    position: relative !important;
    overflow-x: hidden !important;
  }
}

@supports not (-webkit-touch-callout: none) {

  html,
  body {
    position: relative !important;
    overflow-x: hidden !important;
  }
}

@media (pointer: fine) {
  #multi-select-inactive:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  div.sideTabNav>div>div>a.tab-inactive:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  div.singleViewTabs>div>div>a.tab-inactive:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .remote-pending {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  .row-hover:hover {
    background-color: rgba(75, 69, 69, 0.2);
    border-radius: 8px;
  }

  .logout-hover:hover>p,
  .logout-hover:hover>span {
    color: gray !important;
  }

  .button-hover:hover {
    color: gray !important;
  }

  .button-hover:hover>circle {
    fill: gray !important;
  }

  /* z-index issue chart card overlap */
  /* .tooltip:hover .tooltiptext {
    visibility: visible;
  } */

  .dryer-card:hover>div>div>div.group-1 {
    background: rgba(75, 67, 67, 0.2);
  }
}

/* detecting mobile landscape */
@media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  .remote-modal {
    top: 95%;
    left: 50%,
  }

  .remote-sub-modal {
    top: 65%;
    left: 50%,
  }

  .admin-user-modal {
    top: 95%;
    left: 50%,
  }
}